<h2 class="mb-3">Anchor.AI</h2>

<h4>User Access</h4>
<textarea class="user-access-textarea" [(ngModel)]="emails"></textarea>

<p>Can take up to one minute for changes to be visible in PROFIS Engineering.</p>

<p class="warning">Changes in Anchor.AI access also need to be updated for corresponding environment in LaunchDarkly:
<a href="https://app.launchdarkly.com/projects/pe-bp/flags/P_PE_AnchorAIEnabled/targeting?env=dev&env=qa&env=stage&env=production&env=hotfix&selected-env=dev">P_PE_AnchorAIEnabled</a>
and
<a href="https://app.launchdarkly.com/projects/pe-bp/flags/P_PE_AnchorAIDebug/targeting?env=dev&env=qa&env=stage&env=production&env=hotfix&selected-env=dev">P_PE_AnchorAIDebug</a>!
</p>

<button type="button" class="save btn btn-primary" (click)="save()">Save</button>


<br/>
<br/>


<h4>Regions</h4>
<div id="anchoraiRegionForm"></div>

<p class="warning">Changes in Anchor.AI access also need to be updated for corresponding environment in LaunchDarkly:
<a href="https://app.launchdarkly.com/projects/pe-bp/flags/P_PE_AnchorAIRegions/targeting?env=dev&env=qa&env=stage&env=production&env=hotfix&selected-env=dev">P_PE_AnchorAIRegions</a>!
</p>

<button type="button" class="save btn btn-primary" (click)="saveRegion()">Save</button>
