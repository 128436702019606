var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"HubId") || (depth0 != null ? lookupProperty(depth0,"HubId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"HubId","hash":{},"data":data,"loc":{"start":{"line":103,"column":39},"end":{"line":103,"column":48}}}) : helper)))
    + "\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"Selected") || (depth0 != null ? lookupProperty(depth0,"Selected") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Selected","hash":{},"data":data,"loc":{"start":{"line":103,"column":50},"end":{"line":103,"column":62}}}) : helper)))
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"Name") || (depth0 != null ? lookupProperty(depth0,"Name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Name","hash":{},"data":data,"loc":{"start":{"line":103,"column":63},"end":{"line":103,"column":71}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"hiltiApplicationsAddForm\">\n    <fieldset form=\"hiltiApplicationsAddForm\" name=\"hilti-applications\">\n        <div class=\"form-group row\">\n            <label class=\"col-sm-3 col-form-label\" for=\"applicationName\">Application name</label>\n            <div class=\"col-sm-9\">\n                <input type=\"text\" class=\"form-control note-input\" id=\"applicationName\" name=\"applicationName\" maxlength=\"255\" required />\n            </div>\n\n            <span class=\"offset-3 col-sm-9 col-form-label\">\n                Please ensure this translation key exists:\n                <br/>\n                <b id=\"hiltiApplicationApplicationName\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"DisplayApplicationName") || (depth0 != null ? lookupProperty(depth0,"DisplayApplicationName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DisplayApplicationName","hash":{},"data":data,"loc":{"start":{"line":12,"column":56},"end":{"line":12,"column":82}}}) : helper)))
    + "</b>\n            </span>\n        </div>\n    </fieldset>\n\n    <fieldset form=\"hiltiApplicationsAddForm\" name=\"hilti-applications\">\n        <div class=\"form-group row\">\n            <label class=\"col-sm-3 col-form-label\" for=\"applicationSubtitle\">Application subtitle</label>\n            <div class=\"col-sm-9\">\n                <input type=\"text\" class=\"form-control note-input\" id=\"applicationSubtitle\" name=\"applicationSubtitle\" maxlength=\"255\" />\n            </div>\n\n            <span class=\"offset-3 col-sm-9 col-form-label\">\n                Please ensure this translation key exists:\n                <br/>\n                <b id=\"hiltiApplicationApplicationSubtitle\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"DisplayApplicationSubtitle") || (depth0 != null ? lookupProperty(depth0,"DisplayApplicationSubtitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"DisplayApplicationSubtitle","hash":{},"data":data,"loc":{"start":{"line":27,"column":60},"end":{"line":27,"column":90}}}) : helper)))
    + "</b>\n            </span>\n        </div>\n    </fieldset>\n\n    <hr/>\n\n    <fieldset form=\"hiltiApplicationsAddForm\" name=\"hilti-applications\">\n        <div class=\"form-group row\">\n            <label class=\"col-sm-3 col-form-label\" for=\"Image\">Image (size MUST be 128x128 or smaller)</label>\n            <div class=\"col-sm-9\">\n                <img id=\"imagePreview\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"Image") || (depth0 != null ? lookupProperty(depth0,"Image") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"Image","hash":{},"data":data,"loc":{"start":{"line":38,"column":44},"end":{"line":38,"column":53}}}) : helper)))
    + "\" />\n                <input type=\"file\" id=\"image\" name=\"image\" accept=\".jpg,.png\" required />\n            </div>\n        </div>\n    </fieldset>\n\n    <hr/>\n\n    <fieldset form=\"hiltiApplicationsAddForm\" name=\"hilti-applications\">\n        <div class=\"form-group row\">\n            <label class=\"col-sm-3 col-form-label\" for=\"showNewLabel\">Show \"New\" label</label>\n            <div class=\"col-sm-9\">\n                <input type=\"checkbox\" id=\"showNewLabel\" name=\"showNewLabel\">\n            </div>\n        </div>\n    </fieldset>\n\n\n    <fieldset form=\"hiltiApplicationsAddForm\" name=\"hilti-applications\">\n        <div class=\"form-group row\">\n            <label class=\"col-sm-3 col-form-label\" for=\"active\">Active</label>\n            <div class=\"col-sm-9\">\n                <input type=\"checkbox\" id=\"active\" name=\"active\">\n            </div>\n        </div>\n    </fieldset>\n\n    <fieldset form=\"hiltiApplicationsAddForm\" name=\"hilti-applications\">\n        <div class=\"form-group row\">\n            <label class=\"col-sm-3 col-form-label\" for=\"activatedPerHub\">Activated per-hub for all users</label>\n            <div class=\"col-sm-9\">\n                <input type=\"checkbox\" id=\"activatedPerHub\" name=\"activatedPerHub\" "
    + alias4(((helper = (helper = lookupProperty(helpers,"ActivatedPerHub") || (depth0 != null ? lookupProperty(depth0,"ActivatedPerHub") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ActivatedPerHub","hash":{},"data":data,"loc":{"start":{"line":69,"column":83},"end":{"line":69,"column":102}}}) : helper)))
    + ">\n                <p class=\"note-display small\">(if selected activated per hub, for all users; otherwise activated for specified emails only!)</p>\n            </div>\n        </div>\n    </fieldset>\n\n    <div class=\"access-subform "
    + alias4(((helper = (helper = lookupProperty(helpers,"AccessSubformClass") || (depth0 != null ? lookupProperty(depth0,"AccessSubformClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"AccessSubformClass","hash":{},"data":data,"loc":{"start":{"line":75,"column":31},"end":{"line":75,"column":53}}}) : helper)))
    + "\">\n        <div class=\"access-subform-emails\">\n            <fieldset form=\"hiltiApplicationsAddForm\" name=\"hilti-applications\">\n                <div class=\"form-group row\">\n                    <label class=\"col-sm-3 col-form-label\" for=\"perUserAccessUrl\">Download link</label>\n                    <div class=\"col-sm-9\">\n                        <input type=\"text\" class=\"form-control note-input\" id=\"perUserAccessUrl\" name=\"perUserAccessUrl\" maxlength=\"255\">\n                    </div>\n                </div>\n            </fieldset>\n\n            <fieldset form=\"hiltiApplicationsEditForm\" name=\"hilti-applications\">\n                <div class=\"form-group row\">\n                    <label class=\"col-sm-3 col-form-label\" for=\"userAccessEmails\">Emails</label>\n                    <div class=\"col-sm-9\">\n                        <textarea class=\"form-control user-access-textarea\" id=\"userAccessEmails\" name=\"userAccessEmails\"\n                            placeholder=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"UserAccessEmailsPlaceholder") || (depth0 != null ? lookupProperty(depth0,"UserAccessEmailsPlaceholder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"UserAccessEmailsPlaceholder","hash":{},"data":data,"loc":{"start":{"line":91,"column":41},"end":{"line":91,"column":72}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"UserAccessEmails") || (depth0 != null ? lookupProperty(depth0,"UserAccessEmails") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"UserAccessEmails","hash":{},"data":data,"loc":{"start":{"line":91,"column":74},"end":{"line":91,"column":94}}}) : helper)))
    + "</textarea>\n                    </div>\n                </div>\n            </fieldset>\n        </div>\n\n        <div class=\"access-subform-hubs\">\n            <div class=\"form-group row\">\n                <label class=\"col-sm-3 col-form-label\">Hubs</label>\n                <div class=\"col-sm-9\">\n                    <select id=\"hubsFilter\" multiple class=\"form-control\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"HubsFilter") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":24},"end":{"line":104,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n\n                <div class=\"col-md-12\">\n                    <label class=\"col-form-label\">Selected hubs</label>\n                    <div id=\"hubs\" class=\"hubs\"></div>\n                </div>\n            </div>\n        </div>\n    </div>\n</form>\n";
},"useData":true});