import { Router, UrlTree } from '@angular/router';

/**
 * If an error occurs inside action we are redirected to the error page. Url does not change.
 */
export async function handleGuardAction(router: Router, action: () => boolean | UrlTree | Promise<boolean | UrlTree>): Promise<boolean | UrlTree> {
    try {
        return await Promise.resolve(action());
    }
    catch (error) {
        // throw error does not get logged into the console when navigating (Angular bug?)
        console.error(error);

        await router.navigateByUrl('/error', { skipLocationChange: true });

        return false;
    }
}
