import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { environment } from '../../environments/environment';
import { AnchorAiComponent } from '../components/anchor-ai/anchor-ai.component';
import {
    ApplicationVersionsComponent
} from '../components/application-versions/application-versions.component';
import { DecodeUidComponent } from '../components/decode-uid/decode-uid.component';
import { DevelopmentApiComponent } from '../components/development-api/development-api.component';
import { ErrorComponent } from '../components/error/error.component';
import { ExportDesignsComponent } from '../components/export-designs/export-designs.component';
import { ExternalAppsComponent } from '../components/external-apps/external-apps.component';
import { GdprComponent } from '../components/gdpr/gdpr.component';
import {
    HiltiApplicationsComponent
} from '../components/hilti-applications/hilti-applications.component';
import { HomeComponent } from '../components/home/home.component';
import {
    MarketingCampaignComponent
} from '../components/marketing-campaign/marketing-campaign.component';
import { NoopComponent } from '../components/noop/noop.component';
import {
    ProjectManagementComponent
} from '../components/project-management/project-management.component';
import { RegionLinksComponent } from '../components/region-links/region-links.component';
import { ReleaseNotesComponent } from '../components/release-notes/release-notes.component';
import { UserAgreementComponent } from '../components/user-agreement/user-agreement.component';
import { VersionComponent } from '../components/version/version.component';
import { AuthGuard } from '../guards/auth.guard';
import { ErrorGuard } from '../guards/error.guard';
import { InitializationGuard } from '../guards/initialization.guard';
import { OauthCallbackGuard } from '../guards/oauth-callback.guard';
import { routePaths } from '../modules/route-info';

const routes: Routes = [
    { path: 'project-management', component: ProjectManagementComponent },
    { path: 'export-designs', component: ExportDesignsComponent },
    { path: 'decode-uid', component: DecodeUidComponent },
    { path: 'release-notes', component: ReleaseNotesComponent },
    { path: 'marketing-campaign', component: MarketingCampaignComponent },
    { path: 'user-agreement', component: UserAgreementComponent },
    { path: 'gdpr', component: GdprComponent },
    { path: 'region-links', component: RegionLinksComponent },
    { path: 'anchor-ai', component: AnchorAiComponent },
    { path: 'hilti-applications', component: HiltiApplicationsComponent},
    { path: 'external-apps', component: ExternalAppsComponent},
    { path: 'application-versions', component: ApplicationVersionsComponent},
    { path: 'version', component: VersionComponent},
    { path: '', component: HomeComponent, pathMatch: 'full' }
];

// development api route should only be added if development api is enabled
if (environment.enableDevelopmentApi) {
    routes.push({ path: 'development-api', component: DevelopmentApiComponent});
}

// every normal route is guarded with AuthGuard and InitializationGuard
for (const route of routes) {
    route.canActivate = [AuthGuard, InitializationGuard];
}

// oauth callback validates the returned login state and calls GetToken to get user info
// no component is shown but we have to provide it (NoopComponent)
routes.push({ path: routePaths.oauthCallback, canActivate: [OauthCallbackGuard], component: NoopComponent });

// error page that is displayed on the original url router.navigateByUrl('/error', { skipLocationChange: true })
routes.push({ path: routePaths.error, canActivate: [ErrorGuard], component: ErrorComponent });

// got to home page if no route was found
routes.push({ path: '**', redirectTo: '/' });

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
